import {user, scheme} from '@/api'
import {mapGetters} from "vuex";
import {rechargeV2Api} from "../../../api/v2/rechargeV2Api";
import {consumeRecordV2Api} from "../../../api/v2/consumeRecordV2Api";
import wxValidate from "../../../config/wx.validate";
import {sleep} from "../../../config/util";

export const unlockMixin = {
  mixins: [wxValidate],
  created() {
    if (this.isWechatEnv) {
      this.wxSignature().then();
    }
  },
  computed: {
    ...mapGetters(['userInfo', 'wallet','userVip']),

    finalPrice() {
      let price = this.plan.downloadPrice;
      if (this.vipInfo.vipDiscount < 10) {
        price = price * this.vipInfo.vipDiscount / 10;
      }
      if (this.plan.isInDiscount) {
        price = price * this.plan.discountVal / 10;
      }
      return this.moneyFilter(price);
    },


    // 方案价格 - 钱包
    deltaYanzhi() {
      const finalPrice = this.finalPrice * 1000000
      const totalRemainValue = this.wallet.totalRemainValue * 1000000
      return ((finalPrice * 10 - totalRemainValue) / 1000000).toFixed(1)
    },

    isWechatEnv() {
      return this.getBrowser() === 'wechat';
    }

  },

  data() {
    return {
      verifyCode: '',
      vipInfo: {
        vipRightUserId: '',
        vipRightId: '3',    // 会员权益id
        expireTime: '',     // 会员超时时间
        lastExpireVipRightId: '',  // 已超时的会员id
        lastExpireTime: '',        // 超时会员的超时时间
        freeCase: 0,               // 用户免费解锁总额度
        remainFreeCase: 0,         // 剩余的可解锁免费方案数量
        vipFreeCase: 0,            // 用户会员免费解锁的总额度
        remainVipFreeCase: 0,      // 剩余的可解锁会员方案数量
        vipDiscount: 0,            // 购买会员折扣的折扣
        superVipDiscount: 0,       // 超级会员购买会员折扣的折扣
        vipDiscountOnPlanList: 0,  // 解锁合集的折扣
        lastNotifyJoinTime: null,  // 最后一次通知入会时间
        lastNotifyExpireTime: null // 最后一次通知过期时间
      },
      payType: 1,
      payTypeEnum: {
        wx: 1,
        alipay: 2
      },
      plan: {}
    }
  },

  methods: {
    async show(vipInfo, plan) {
      await this.$store.dispatch('getWallet');
      this.vipInfo = vipInfo;
      this.plan = plan;
      this.$refs.model.toggleDialog();
    },

    hide(){
      this.$refs.model.hide()
    },


    showVipPopup(){
      this.$emit('showVipPopup')
      this.hide()
    },


    share(){
      this.hide()
      this.$emit('share')
    },


    // 解锁
    async unlock() {
      try {
        this.$loading();
        const {code, msg, data} = await scheme.unlockAdd({
          projectPlanId: this.$route.params.id,
          userId: this.userInfo.id,
          email: this.userInfo.email,
          verifyCode: this.verifyCode
        });
        if (code != 1) {
          if(msg === '您的解锁过于频繁，请使用电脑输入验证码解锁'){
            return this.$emit('verifyCode')
          }
          return this.$toast.info(msg);
        }
        this.$refs.model.toggleDialog();
        // this.$toast.success('已成功发送，请注意查收邮件')
        await sleep(100)
        this.$emit('success',data)
      } finally {
        this.$loading.close();
      }
    }

  }

}
