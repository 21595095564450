<template>
  <van-popup v-model="visible" position="bottom" closeable>
    <div class="pay-type">
      <div class="title">请选择支付方式</div>
      <div class="pay-type-item" @click="choose(1)">
        <img src="https://cdn.001ppt.cn/pc2/static/imgs/svg/wxpay-green.svg" alt="">
        <div>微信支付</div>
      </div>
      <!--      <div class="pay-type-item" @click="choose(2)">-->
      <!--        <img src="https://cdn.001ppt.cn/pc2/static/imgs/svg/alipay-blue.svg" alt="">-->
      <!--        <div>支付宝支付</div>-->
      <!--      </div>-->
    </div>
  </van-popup>
</template>

<script>
import {unlockMixin} from "../../views/discover/components/unlockMixin";
import {mapGetters} from "vuex";

export default {
  mixins: [unlockMixin],
  props: ['autoHide'],
  computed: {
    ...mapGetters(['userInfo'])
  },
  data() {
    return {
      visible: false
    }
  },


  methods: {
    show() {
      if (this.isWechatEnv) {
        return this.choose(1);
      } else {
        return this.choose(1)
      }
      this.visible = true
    },

    hide() {
      this.visible = false
    },


    // 选择支付方式
    choose(payType) {
      let paySubType = null
      if (this.isWechatEnv) {
        if (!this.userInfo.wxServiceOpenId) {
          this.$toast.info('请绑定微信后支付')
          setTimeout(() => {
            this.$router.push({
              path: '/wx',
              query: {
                redirect: window.location.href
              }
            })
          }, 1000)
        }
        paySubType = 11
      } else if (payType === 1) {
        paySubType = 13
      } else if (payType === 2) {
        paySubType = 21
      }
      this.$emit('choose', {payType, paySubType})
      if (this.autoHide) {
        this.hide()
      }
    }

  }
}
</script>

<style scoped lang="less">

.pay-type {
  border-radius: 4px;
}

.title {
  text-align: center;
  padding: 8px;
  font-size: 14px;
}

.pay-type-item {
  display: flex;
  align-items: center;
  font-size: 14px;
  padding: 16px;

  img {
    width: 30px;
    height: 30px;
    margin-right: 8px;
  }
}

</style>
