<template>
  <div class="company-item">
    <img class="logo" :src="detail.logo"/>
    <div class="name">{{ detail.name }}</div>
  </div>
</template>

<script>
export default {
  props: ['detail'],
}
</script>

<style scoped lang="less">
.company-item{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px;
  font-size: 12px;
  background: #fff;
  margin: 4px;
  flex-shrink: 0;
  border-radius: 4px;
}

.company-item .logo{
  height: 24px;
  width: 24px;
  border-radius: 1000px;
  border: 1px solid #e9e9e9;
  margin-right: 4px;
}
</style>
