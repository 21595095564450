import {v2Request} from "./v2Request";


export const consumeRecordV2Api = {

  async findStatusById(id) {
    return v2Request.get(`/consumeRecord/findStatusById`, {params: {id}});
  },


  // 查找我在一定时间内的支付总额
  findMyPayTotal(params) {
    return v2Request.get(`/userConsumeRecord/findMyPayTotal`, {params});
  },


  // 查找我的充值记录
  async findMyPayRecordPage({current, size, startTime, endTime}) {
    return v2Request.get('/userConsumeRecord/findMyPayRecordPage', {
      params: {current, size, startTime, endTime}
    })
  },

};
