import {v2Request} from "./v2Request";


export const rechargeV2Api = {

  /**
   * 发起充值支付
   */
  startPayRecharge({money, payType, paySubType}) {
    const returnUrl = 'https://m.001ppt.com/record?tab=RECHARGE';
    return v2Request.post(`recharge/startPayRecharge`, {
      money,
      payType,
      paySubType,
      returnUrl
    })
  }

};
