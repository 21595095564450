<template>
  <div class="qa member-qa">
    <div class="title">
      <img class="title-img" src="https://cdn.001ppt.cn/pc2/static/vip/vip-page-title-开票企业.png" alt="">
    </div>
    <div class="qa-main">
      <div class="company-line" style="width: 80%;overflow: hidden;margin: 0 auto">
        <div class="company-anime-main company-anime-main-left">
          <company-item :detail="item" v-for="(item, index) in arr1" :key="index"/>
        </div>
      </div>
      <div class="company-line">
        <div class="company-anime-main company-anime-main-right">
          <company-item :detail="item" v-for="(item, index) in arr2" :key="index"/>
        </div>
      </div>
      <div class="company-line" style="width: 80%;overflow: hidden;margin: 0 auto">
        <div class="company-anime-main company-anime-main-left">
          <company-item :detail="item" v-for="(item, index) in arr3" :key="index"/>
        </div>
      </div>
    </div>

    <div class="bill-btn" @click="visibles.bill = true">无忧报账</div>
    <van-popup v-model="visibles.bill" round>
      <div class="bill">
        <div class="bill-title">长按识别添加小助手</div>
        <div class="bill-desc" style="margin-top: 4px">提供登录手机号与开票信息即可</div>
        <img class="bill-qr" src="https://cdn.001ppt.cn/pc2/static/vip/vip-page-qr.png" alt="">
        <div class="bill-desc">次日开具完成</div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import {companyV2Api} from "../../api/v2/companyV2Api";
import CompanyItem from "./companyItem";

export default {
  components: {CompanyItem},
  data() {
    return {
      arr1: [],
      arr2: [],
      arr3: [],
      visibles:{
        bill: false
      }
    }
  },


  async mounted() {
    const res = await companyV2Api.randomGet()
    const len = Math.floor(res.length / 3)
    this.arr1 = res.slice(0, len)
    this.arr2 = res.slice(len, len * 2)
    this.arr3 = res.slice(len * 2)
  }

}
</script>

<style scoped lang="less">
@keyframes move-left {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
}

@keyframes move-right {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}

.qa {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.title {
  display: flex;
  align-items: center;
  justify-content: center;
}

.title-img {
  width: 200px;
  height: 18px;
}

.qa-main {
  width: 80vw;
  border-radius: 6px;
  overflow: hidden;
  margin-top: 16px;
}

.company-line {
  display: flex;
  align-items: center;
  justify-content: center;
}

.company-anime-main {
  display: flex;
  align-items: center;
  -webkit-animation-name: move-left;
  -webkit-animation-duration: 10s;
  -webkit-animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-delay: 2s;
  -moz-animation-name: move-left;
  -moz-animation-duration: 10s;
  -moz-animation-timing-function: linear;
  -moz-animation-iteration-count: infinite;
  -moz-animation-delay: 2s;
  -ms-animation-name: move-left;
  -ms-animation-duration: 10s;
  -ms-animation-timing-function: linear;
  -ms-animation-iteration-count: infinite;
  -ms-animation-delay: 2s;
  -o-animation-name: move-left;
  -o-animation-duration: 10s;
  -o-animation-timing-function: linear;
  -o-animation-iteration-count: infinite;
  -o-animation-delay: 2s;
  animation-name: move-left;
  animation-duration: 10s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-delay: 2s;
}

.company-anime-main-right {
  -ms-animation-name: move-right;
  -o-animation-name: move-right;
  -moz-animation-name: move-right;
  -webkit-animation-name: move-right;
  animation-name: move-right;
}

.bill-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #151D36;
  color: #fff;
  width: 120px;
  height: 38px;
  border-radius: 100px;
  font-size: 14px;
  margin-top: 12px;
}

.bill{
  padding: 16px 32px;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.bill-title{
  font-size: 18px;
  font-weight: bolder;
}

.bill-desc{
  color: #999;
  font-size: 13px;
}

.bill-qr{
  height: 200px;
  width: 200px;
}
</style>
