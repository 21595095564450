<template>
  <div class="member-qa qa">
    <div class="title">
      <img class="title-img" src="https://cdn.001ppt.cn/pc2/static/vip/vip-page-title-常见问题.png" alt="">
    </div>

    <div class="qa-main">
      <van-collapse v-model="activeNames" accordion>
        <van-collapse-item v-for="item in questions" :key="item.id">
          <template #title>
            <div style="font-size: 12px">{{item.title}}</div>
          </template>
          <template #default>
            <div class="qa-content" v-html="item.content" style="font-size: 12px"></div>
          </template>
        </van-collapse-item>
      </van-collapse>
    </div>

  </div>
</template>

<script>
import {questionV2Api} from "../../api/v2/questionV2Api";

export default {
  data() {
    return {
      activeNames: ['1'],
      questions: []
    }
  },

  async mounted() {
    this.questions = await questionV2Api.findByClassify('加入会员')
  }
}
</script>

<style scoped lang="less">
.qa {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.title {
  display: flex;
  align-items: center;
  justify-content: center;
}

.title-img {
  width: 200px;
  height: 18px;
}

.qa-main {
  width: 80vw;
  border-radius: 6px;
  overflow: hidden;
  margin-top: 16px;
}
</style>

<style lang="less">
.member-qa .qa-content p{
  font-size: 12px !important;
}
</style>
