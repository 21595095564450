<template>
  <div class="user-praise-in-vip">
    <div class="user-praise-in-vip-header">
      <div style="display: flex;align-items: flex-end">
        <div class="user-praise-in-vip-title">真实评价</div>
        <div class="user-praise-in-vip-amount">(999+)</div>
      </div>
      <div class="user-praise-in-vip-header-more" @click="loadUserPraises(3)">
        换一批
        <i class="iconfont icon-change switch-btn-icon" style="margin-left: 4px"></i>
      </div>
    </div>

    <div class="user-praise-in-vip-item" v-for="(item,index) in userPraise.records" :key="item.id" @click="preview(index)">
      <div class="user-praise-in-vip-item-username">
        <img class="user-praise-in-vip-item-avatar" :src="item.avatarUrl" alt="">
        {{ item.nickName }}
      </div>
      <div class="user-praise-in-vip-item-rate">
        <van-rate :value="5" color="#ffd21e"/>
      </div>
      <div class="user-praise-in-vip-item-content">{{ item.content }}</div>
    </div>
  </div>
</template>

<script>
import {userPraiseV2Api} from "../../api/v2/userPraiseV2Api";
import {sleep} from "../../config/util";
import {ImagePreview} from "vant";

export default {
  data() {
    return {
      loading: true,
      userPraise: {
        records: []
      }
    }
  },


  created() {
    this.loadUserPraises(3);
  },


  methods: {
    // 加载真实评价
    async loadUserPraises(size) {
      try {
        this.loading = true
        const res = await userPraiseV2Api.findPage({current: 1, size: size || 100000});
        this.userPraise.records = res.records;
      } catch (e) {
        console.error(e)
        this.$toast.info(e.msg || '加载失败')
      } finally {
        await sleep(300)
        this.loading = false
      }
    },

    // 预览
    preview(index) {
      ImagePreview({
        images: this.userPraise.records.map(item => item.screenshotUrl),
        startPosition: index
      });
    }
  }
}
</script>

<style lang="less" scoped>
.user-praise-in-vip-header {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  padding: 0 36px 8px 36px;
}

.user-praise-in-vip-header-more{
  font-size: 12px;
  color: #666;
  display: flex;
  align-items: center;
}

.user-praise-in-vip-header-more .iconfont{
  font-size: 10px;
  color: #999;
}

.user-praise-in-vip-title {
  font-size: 14px;
  font-weight: bolder;
  color: #151D36;
}

.user-praise-in-vip-amount {
  font-size: 12px;
  color: #999;
  padding-left: 4px;
}

.user-praise-in-vip-item {
  background: #fff;
  border-radius: 6px;
  margin: 8px 30px 0 30px;
  padding: 16px;
}

.user-praise-in-vip-item-avatar {
  width: 24px;
  height: 24px;
  border-radius: 100px;
  margin-right: 4px;
}

.user-praise-in-vip-item-username {
  display: flex;
  align-items: center;
  font-size: 13px;
}

.user-praise-in-vip-item-content {
  color: #999;
  font-size: 12px;
}

.user-praise-in-vip-item-rate {
  padding: 4px 0 0 0;
}
</style>
