import { v2Request } from './v2Request';


export const userPraiseV2Api = {

  async findPage({ current, size }) {
    return v2Request.post(`userPraise/findPage`, { current, size });
  },

};
