import {v2Request} from "./v2Request";


export const questionV2Api = {

  findByClassify(classify) {
    return v2Request.get(`question/findByClassify`, {params: {classify}})
  }

}
